<template>
  <div class="search app_content">
    <div class="warp_body">
      <van-search
          v-model="keyword"
          show-action
          placeholder="搜索你想要的工作"
          @search="onSearch"
          shape="round"
          background="#4DBAFD"
      >
        <template #action>
          <div @click="onClickButton" style="color:#FFFFFF;">搜索</div>
        </template>
      </van-search>
      <van-empty :description="emptyContent" image="search" v-show="showEmpty"/>
      <div v-for="item in jobList" :key="item" class="jobList">
        <job-card :job="item"></job-card>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRaw} from 'vue';
import {onBeforeMount, reactive, toRefs, nextTick, onActivated, onDeactivated} from 'vue'
import {Toast} from 'vant'
import {useRouter} from 'vue-router'
import req from "../request/request";
import API_ORDER from "../api/Order";
import API from "../api/User";
import router from "../router";
import ChooseTime from "@/components/common/chooseTime";
import JobCard from "@/components/common/JobCard";
import utils from "@/util/utils";

export default {
  name: 'PublishJob',
  components: {JobCard},
  setup() {

    onMounted(() => {
    });

    const data = reactive({
          showEmpty: false,
          emptyContent: '',
          areaList: [],
          jobList: [],
          keyword: ''
        },
    )

    const methodsMap = {
      onSearch: () => {
        data.showEmpty = false;
        req.request(API_ORDER.jobList, {keyword: data.keyword}, {method: 'GET'}).then((res) => {
          let list = res.data.data.records
          data.jobList = []
          if (list.length > 0) {
            data.showEmpty = false;
            list.forEach(
                (d)=>{
                  utils.getArea(d.companyArea).then(
                      v =>{
                        d.companyArea = v;
                        data.jobList.push(d)
                      }
                  )
                }
            )

          } else {
            data.showEmpty = true;
            data.emptyContent = "抱歉，未找到相关内容"
          }
        })
      },
      onClickButton: () => {
        methodsMap.onSearch()
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.warp_body {
  .jobList {
    padding: 5px;
  }
}
</style>
